import { Requirement } from "@src/appV2/Accounts/Documents/types";
import { environmentConfig } from "@src/appV2/environment";
import { Worker } from "@src/appV2/Worker/api/types";
import { logApiFailureEvent } from "@src/lib/analytics";
import request from "superagent";

import {
  CheckEmailResponse,
  CheckrFeatureFlagValidationResponse,
  CheckrResponse,
  OnboardingApiData,
  UpdateUnder18Data,
} from "./model";
import { getAuthHeader } from "../../superagent";
import { PackageType } from "../constants/BACKGROUND_CHECK_CONSTANTS";

export const updateAgentData = async (updateData: OnboardingApiData): Promise<any> => {
  try {
    const updateAgentEndpoint = `${environmentConfig.REACT_APP_BASE_API_URL}/onboarding/updateAgent`;
    const { body } = await request
      .put(updateAgentEndpoint)
      .set(await getAuthHeader())
      .send(updateData);
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

export const updateUnder18 = async (updateUnder18Data: UpdateUnder18Data): Promise<void> => {
  try {
    await request
      .put(`${environmentConfig.REACT_APP_BASE_API_URL}/onboarding/updateUnder18`)
      .set(await getAuthHeader())
      .send(updateUnder18Data);
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

export const fireVerifyDocumentZapierHook = async (
  agent: Worker,
  requirement: {
    document: Requirement;
  }
): Promise<void> => {
  return await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/onboarding/document/fireZapierHook`)
    .set(await getAuthHeader())
    .send({ agent, requirement })
    .then(({ body }) => body)
    .catch((error) => {
      logApiFailureEvent(error);
      throw error;
    });
};

export const fireVerifyLicenseZapierHook = async (worker: Worker): Promise<void> => {
  return await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/onboarding/license/fireZapierHook`)
    .set(await getAuthHeader())
    .send({ agent: worker })
    .then(({ body }) => body)
    .catch((error) => {
      logApiFailureEvent(error);
      throw error;
    });
};

export const checkIfEmailExists = async (email: string): Promise<CheckEmailResponse> => {
  try {
    const { body } = await request
      .get(`${environmentConfig.REACT_APP_BASE_API_URL}/user/checkIfEmailExists`)
      .retry(1, (err) => {
        if (!err) {
          return false;
        }
        return true;
      })
      .set(await getAuthHeader())
      .query({ email });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

export const checkIfPhoneExists = async (phone: string): Promise<CheckEmailResponse> => {
  try {
    const { body } = await request
      .get(`${environmentConfig.REACT_APP_BASE_API_URL}/user/checkIfPhoneExists`)
      .retry(1, (err) => {
        if (!err) {
          return false;
        }
        return true;
      })
      .set(await getAuthHeader())
      .query({ phone });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

export const completeSignup = async (): Promise<void> => {
  try {
    await request
      .put(`${environmentConfig.REACT_APP_BASE_API_URL}/onboarding/completeSignup`)
      .set(await getAuthHeader());
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

export const showSSNPage = async (): Promise<any> => {
  try {
    const { body } = await request
      .get(`${environmentConfig.REACT_APP_BASE_API_URL}/onboarding/validateSsnFeatureFlag`)
      .retry(1, (err) => {
        if (!err) {
          return false;
        }
        return true;
      })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

export const createCheckrInvite = async (
  email: string | undefined,
  packageType: PackageType
): Promise<CheckrResponse> => {
  try {
    const { body } = await request
      .post(`${environmentConfig.REACT_APP_BASE_API_URL}/onboarding/checkr/invitation`)
      .set(await getAuthHeader())
      .send({ email, packageType });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

export const showCheckrInviteLink = async (): Promise<CheckrFeatureFlagValidationResponse> => {
  try {
    const { body } = await request
      .get(
        `${environmentConfig.REACT_APP_BASE_API_URL}/onboarding/validateCheckrBackgroundCheckFeatureFlag`
      )
      .retry(1, (err) => {
        if (!err) {
          return false;
        }
        return true;
      })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

export const verifyEmail = async (email: string): Promise<boolean> => {
  try {
    const { body } = await request
      .get(`${environmentConfig.REACT_APP_BASE_API_URL}/onboarding/validateEmail`)
      .retry(1, (err) => {
        if (!err) {
          return false;
        }
        return true;
      })
      .set(await getAuthHeader())
      .query({ email });
    return body?.isEmailDomainValid;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};
